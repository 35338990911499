<template>
  <el-container class="home-container">
    <!-- ヘッダー -->
    <el-header>
      <div>
        <span>DRTechECShop</span>
      </div>
      <div>
        <el-dropdown @command="handleCommand">
          <div>
            <span style="font-size: 14px; margin-right: 15px; color: #eee;vertical-align:middle">{{ roleName }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided></el-dropdown-item>
            <!--<el-dropdown-item command="商店情報編集">商店情報編集</el-dropdown-item>-->
            <el-dropdown-item command="パスワード変更">パスワード変更</el-dropdown-item>
            <el-dropdown-item command="ログアウト">ログアウト</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <span>ようこそ</span>
      </el-dialog>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu background-color="#2a4b9b" text-color="#fff" active-text-color="#ffffff" unique-opened @select="handleSelect" :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <!-- メニュー　LEVEL１ -->
          <template v-for="(item, index) in menulist">
            <el-menu-item v-if="!item.children" :index="item.url + ''" :key="index">
              <i :class="item.iconclass"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-submenu v-if="item.children" :index="index + ''" :key="index">
              <template slot="title">
                <i :class="item.iconclass"></i>
                <span slot="title">{{ item.name }}</span>
              </template>
              <el-menu-item-group v-for="(item1, index1) in item.children" :key="index1">
                <el-menu-item :index="item1.url + ''">{{ item1.name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <!-- 右側の内容 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左メニューのデータ
      menulist: [],
      // 折りたたみ
      isCollapse: false,
      activePath: '',
      staffName: '',
      merchantId: '',
      role: '',
      dialogVisible: false,
      roleName: ''
    }
  },
  created() {
    this.role = window.localStorage.getItem('currentRole')
    if (this.role == 1) {
      this.roleName = 'システム管理者'
      this.menulist = [
        {
          name: '商品管理',
          iconclass: 'el-icon-s-management',
          url: 'GetProductsList'
        },
        {
          name: '注文管理',
          iconclass: 'el-icon-s-order',
          url: 'GetOrdersList'
        },
        {
          name: 'ユーザー管理',
          iconclass: 'el-icon-user-solid',
          url: 'GetUsersList'
        },
        {
          name: 'アドレス管理',
          iconclass: 'el-icon-s-ticket',
          url: 'GetAddressesList'
        },
        {
          name: '商店管理',
          iconclass: 'el-icon-s-shop',
          url: 'GetMerchantsList'
        },
        {
          name: 'カテゴリ管理',
          iconclass: 'el-icon-s-grid',
          url: 'GetCategoryList'
        },
        {
          name: 'スライド管理',
          iconclass: 'el-icon-picture',
          url: 'GetSlideList'
        },
        {
          name: 'スタッフ管理',
          iconclass: 'el-icon-picture',
          url: 'GetStaffList'
        }
      ]
    } else {
      this.roleName = '商店管理者'
      this.menulist = [
        {
          name: '商品管理',
          iconclass: 'el-icon-s-management',
          url: 'GetProductsList'
        },
        {
          name: '注文管理',
          iconclass: 'el-icon-s-order',
          url: 'GetOrdersList'
        },
        {
          name: 'ユーザー管理',
          iconclass: 'el-icon-user-solid',
          url: 'GetUsersList'
        },
        {
          name: '受取人住所管理',
          iconclass: 'el-icon-s-ticket',
          url: 'GetAddressesList'
        }
      ]
    }
  },
  methods: {
    handleCommand(command) {
      if (command == '商店情報編集') {
        this.personal()
      } else if (command == 'パスワード変更') {
        this.changePsd()
      } else if (command == 'ログアウト') {
        window.localStorage.removeItem('editableTabs')
        window.localStorage.removeItem('activeIndex')
        this.logout()
      }
    },
    // ボタンをクリックして、メニューの折りたたみと展開を切り替えます。
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/GetLogin')
    },
    changePsd() {
      this.$router.push('/UpdatePassword')
    },
    personal() {
      // this.dialogVisible = true
      this.$router.push({
        name: 'GetMerchantsDetails',
        query: {
          merchantId: this.merchantId,
          type: 'update'
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSelect(key, keyPath) {
      this.activePath = key
      window.localStorage.setItem('activePath', this.activePath)
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #093190;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  font-family: 'PingFang SC';
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.el-header > div {
  display: flex;
  align-items: center;
}

.el-header > div span {
  margin-left: 30px;
}

.el-aside {
  background-color: #2a4b9b;
}

.el-aside .el-menu {
  border-right: none;
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: rgb(42, 75, 155);
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

i {
  color: #fff !important;
}

/deep/.el-submenu__title i {
  color: #fff !important;
}

/deep/ .el-menu-item-group__title {
  padding: 0 !important;
}
</style>
